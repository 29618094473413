(function($,sr){

  // debouncing function from John Hann
  // http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
  var debounce = function (func, threshold, execAsap) {
      var timeout;

      return function debounced () {
          var obj = this, args = arguments;
          function delayed () {
              if (!execAsap)
                  func.apply(obj, args);
              timeout = null;
          };

          if (timeout)
              clearTimeout(timeout);
          else if (execAsap)
              func.apply(obj, args);

          timeout = setTimeout(delayed, threshold || 500);
      };
  }
	// smartresize
	jQuery.fn[sr] = function(fn){  return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr); };

}(jQuery,'smartresize'));


/*
 * All java script logic for the mobile layout.
 *
 * The code relies on the jQuery JS library to
 * be also loaded.
 *
 * The logic extends the JS namespace app.*
 */
(function(app, $, undefined) {

	app.responsive = {

		mobileLayoutWidth : 500,

		init : function () {

			$cache = {
					wrapper: $('#wrapper'),
					navigation: $('#navigation'),
					homepageSlider: $('#homepage-slider')
				};

			app.responsive.initializeMobileMenu();
			app.responsive.initializeCustomerServiceMenu();
			app.responsive.initializeMobileBlogMenu();
			app.responsive.initializeTiles();
		},

		// build vertical, collapsable menu
		enableMobileNav : function(){

			$cache.navigation.find('.menu-category')
				.hide()
				.children('li')
					.children('a')
						.click(function(){
							if( (jQuery(this).siblings().length > 0 ) && (!jQuery(this).siblings().is(":visible"))) {
								jQuery(this)
									.append('<span>close</span>')
									.children('span')
										.click(function(){
											jQuery(this).parent().siblings().hide();
											jQuery(this).remove();
											return false;
										})
									.parent().siblings().show();
								return false;
							}
						})
		},

		// revert to standard horizontal menu
		disableMobileNav : function(){
			$cache.navigation.find('.menu-category').show();
			$cache.navigation.find('.level-2').removeAttr('style');
			$cache.navigation.find('.level-1 span').remove();
		},

		// pull the slideshow into a variable to re-use
		rebuildHomepageSlides: function() {
			if($cache.homepageSlider.length > 0){
				var homeCarousel = 	$cache.homepageSlider.data('jcarousel');
				homeCarousel.stopAuto();
				homeCarousel.scroll(1);
				$cache.homepageSlider.find('.slide').width( $cache.wrapper.width());
				$cache.homepageSlider.find('#homepage-slides').css( {'left':'0','width': ($cache.wrapper.width() * $cache.homepageSlider.find('#homepage-slides li').size()) });
				homeCarousel.reload();
			}
		},

		toggleGridWideTileView : function(){

			/*	toggle grid/wide tile	*/
			if(jQuery('.toggle-grid').length == 0 && (jQuery('.pt_order').length == 0) && (jQuery('.pt_content-search-result').length == 0))
			{
				jQuery('.results-hits').prepend('<a class="toggle-grid" href="'+location.href+'">+</a>');
				jQuery('.toggle-grid').click(function(){
					jQuery('.search-result-content').toggleClass('wide-tiles');
					return false;
				});
			}

		},

		// Set images for mobile or tablet
		initializeTiles : function() {
			var tiles = $('.product-tile-mobile');
			if (tiles.length > 0) {
				var images = tiles.find('.thumb-link > img');
				if (images.length > 0) {
					var imageWidth = $(images[0]).width();
					if (imageWidth > 500) {
						images.each(function() {
							$(this).attr('src', $(this).data('tablet'));
						});
					}
					else if (imageWidth > 300) {
						images.each(function() {
							$(this).attr('src', $(this).data('mobile'));
						});
					}
				}
			}
		},

		initializeMobileMenu : function() {
			try {
				var $mobileMenu = $("#mobile-menu");
				// Initialize mobile menu
				if (typeof $mobileMenu.mmenu !== 'undefined') {
					$mobileMenu.mmenu({
						// options
					}, {
						// configuration
						offCanvas: {
							pageSelector: ".page-wrapper"
						}
					});
				}

				var api = $mobileMenu.data('mmenu');

				$(".leftNav-toggleButton").click(function() {
					api.open();
			    });
			} catch (e) {
				// mmenu error
				console.warn('error initializing mmenu: ' + e);
			}
		},

		initializeCustomerServiceMenu : function() {
			var $customerServNav = $(".pt_customer-service #secondary");
			$(".pt_customer-service .mobile-contentnav").click(function() {
				event.stopPropagation();
				if($customerServNav.hasClass("show")) {
					$customerServNav.removeClass("show");
					$customerServNav.hide();
				} else {
					$customerServNav.addClass("show");
					$customerServNav.show();
				}
			});

			// Hide menu if menu is open and user clicks in other area of page
			$("body").click(function() {
				if($customerServNav.hasClass("show")) {
					$customerServNav.removeClass("show");
					$customerServNav.hide();
				}
			});
		},

		initializeMobileBlogMenu : function() {
			if ($(window).width() < 768 && $("#blognav").length) {
				$("#blognav").removeClass('mobile-hide');
				$("#blognav").mmenu({
					//options
					offCanvas: {
						zposition: "front",
						position: "left"
					}

				}, {
					//configurations
					offCanvas: {
						menuWrapperSelector: ".white-overlay"
					}
				});

				var blogmenu = $("#blognav").data("mmenu");
				$(".pt_blog .mobile-contentnav").click(function() {
					if ($(this).hasClass('open')) {
						blogmenu.close();
					    $(".pt_blog .mobile-contentnav").removeClass('open');
					} else {
						blogmenu.open();
						app.responsive.resizeMobileBlogNav();
					    $(".pt_blog .mobile-contentnav").addClass('open');
					    $("#navigation .header-search").css({"left": "0"});
					    $(", html.mm-opened, html.mm-opened body").css({"overflow": "scroll"})
					}
				});
				$("#blognav").click(function() {
					app.responsive.resizeMobileBlogNav();
				});

				$("#blognav").on('closed.mm', function() {
				    $(".pt_blog .mobile-contentnav").removeClass('open');
				});
			}
		},

		resizeMobileBlogNav : function() {
			$('#blognav').height($('#blognav ul.mm-current').height() + 'px');
		}
	}

	$(document).ready(function(){
		app.responsive.init();
		// set up listener so we can update DOM if page grows/shrinks
		$(window).smartresize(function(){
			if (app.SiteID == "pacificcoast") {
				app.product.initCarousel();
				if(typeof app.storefront != "undefined"){
					app.storefront.initCarousel();
				}
			}
			app.product.tile.sync();
			app.responsive.initializeTiles();
		});

	});

}(window.app = window.app || {}, jQuery));
